import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from "../images/logo-kyoto.svg"
import Kyoto from "../images/kyoto.svg"

import Helmet from 'react-helmet'

const Header = ({ siteTitle }) => {
    const [isOpen, setIsOpen] = useState(false)
    const activatorRef = useRef()
    const listRef = useRef()

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    const handleClickOutside = (event) => {
        if (listRef.current.contains(event.target) || activatorRef.current.contains(event.target)) {
            return
        }
        setIsOpen(false)
    }

    const handleScroll = (event) => {
        if (window.scrollY > 100) {
            document.body.classList.add("small");
        } else {
            document.body.classList.remove("small");
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside)
            document.body.classList.add('open');
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
            document.body.classList.remove('open');
        }
        // clean up on unmount
        return function cleanup() {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isOpen])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
    })

    return (
        <>
            <header className="bg-white shadow fixed z-10 mx-auto inset-x-0 top-0">
                <div className="p-4">
                    <div className="flex h-12">
                        <div className="flex-1 w-0 self-center">
                            <div ref={activatorRef} onClick={handleClick} className="md:hidden">
                                <div className="w-7 h-0.5 bg-black"></div>
                                <div className="w-7 h-0.5 bg-black mt-2"></div>
                                <div className="w-7 h-0.5 bg-black mt-2"></div>
                            </div>
                            <div class="hidden md:block">
                                <Link to="https://de.restaurantguru.com/Kyoto-Paderborn" target="_blank" class="p-2 bg-red-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                                    <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs mr-3">New</span>
                                    <span class="mr-2 text-left flex-auto">Restaurant Guru 2023 - Das Beste Sushi</span>
                                    <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
                                </Link>
                            </div>
                        </div>
                        <Link id="logo" to="/" className="flex flex-grow w-0 justify-center">
                            <Logo className="max-h-full max-h-full" />
                        </Link>
                        <div id="kyoto-logo" className="flex-1 flex justify-end"><Kyoto className="max-w-full max-h-full" /></div>
                    </div>
                </div>
                <div  ref={listRef} className={'p-5 bg-white z-20 md:p-0' + 
                    (isOpen ? ' open' : '')} id="mobile-nav-content">
                    <div className="absolute top-5 right-5 w-8 h-8 close md:hidden" ref={activatorRef} onClick={handleClick}></div>
                    <div className="flex flex-col justify-between h-full md:h-auto">
                        <div>
                            <ul className="list-reset text-center pt-12 items-center text-xl md:flex md:pt-0 md:flex-row md:justify-center md:gap-5">
                                <li>
                                    <a href='/' className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline py-3">Home</a>
                                </li>
                                <li>
                                    <Link to="/speisekarte/" className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline py-3">Speisekarte</Link>
                                </li>
                                <li>
                                    <Link to="/team/" className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline py-3">Team</Link>
                                </li>
                                <li>
                                    <Link to="/kontakt/" className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline py-3">Kontakt</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="pb-8 md:hidden">
                            <div className="nav-mobile-table text-center text-sm hidden">
                                <p className="uppercase">Tisch reservieren</p>
                                <Link className="inline-block py-1 px-4 mt-5 mx-auto border border hover:bg-red-500 hover:text-white" to="tel:+495251">Anrufen</Link>
                            </div>
                            <hr className="my-8 hidden" />
                            <div className="text-center text-sm">
                                <h4 className="uppercase mb-2">Öffnungszeiten</h4>
                                <div className="opening-hours">
                                    <div>Mittwoch: Ruhetag</div>
                                    <div>16:00 - 22:00</div>
                                </div>
                            </div>
                            <hr className="my-8" />
                            <ul className="flex justify-center text-sm">
                                <li className="mx-2">
                                    <Link to="/datenschutz/" className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline pb-3">Datenschutz</Link>
                                </li>
                                <li className="mx-2">
                                    <Link to="/impressum/" className="block text-grey-dark no-underline hover:text-grey-lighter hover:text-underline pb-3">Impressum</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
